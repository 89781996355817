<template>
  <div class="stu-education-bg">
    <title-button-comp
      :showMainTitle="showMainTitle"
      :mainTitle="t('stuManagement.eduBackground')"
      :buttonName="t('stuManagement.addEduBackground')"
      @handleAdd="handleAdd"
      :loading="loading"
      :disabled="stuEduBackgroundFormData.eduBackgrounds.length >= 3"
      :addShow="!hideSubmitBtn() || issueInnerAccount()"
    >
      <el-button v-if="[2, 3, 4, 5, 6].includes(companyId()) && !hideSubmitBtn() && isBmsLogin()" type="primary" class="submit-form" @click="fileIdentification" >
        <span>{{ t("stuManagement.fileIdentification") }}</span>
      </el-button>
    </title-button-comp>
    <el-form
      v-for="(eduData, index) in stuEduBackgroundFormData.eduBackgrounds"
      :key="eduData.uniqueKey"
      :unique-key="eduData.uniqueKey"
      :class="[
        'education-bg-form no-border',
        isDisabled(eduData.uniqueKey) && 'disabled',
        !isEditing(eduData.uniqueKey) && 'show-form',
      ]"
      size="small"
      :ref="'eduBgForm'+eduData.uniqueKey"
      :rules="eduBgRules"
      :model="stuEduBackgroundFormData"
      label-position="top"
    >
      <div class="sub-title">
        {{ t("stuManagement.eduBackground")
        }}{{ stuEduBackgroundFormData.eduBackgrounds.length - index }}
        <owl-btn-set
          :update-show="isDisabled(eduData.uniqueKey)"
          @update="updateEdu(eduData)"
          delete-show
          @delete="delEduBackground(eduData, index, eduData.uniqueKey)"
          v-if="!hideSubmitBtn() || issueInnerAccount()"
        />
      </div>
      <el-row align="middle" :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.institutionNum')"
            :prop="'eduBackgrounds.' + index + '.viewOrder'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.viewOrder"
              @change="handleChangeViewOrder(eduData)"
              :options="viewOrderOption"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.educationType')"
            :prop="'eduBackgrounds.' + index + '.educationType'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.educationType"
              @change="educationTypeChange(eduData, index)"
              :options="levelOption"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="eduData.fkInstitutionIdName">
          <div class="cus-label">
            <div>
              {{t('stuManagement.institutionAnalysis')}}: 
              <span class="text" v-html="eduData.fkInstitutionIdName"></span>
            </div>
            <el-button style="width: 110px;margin-left:16px;" type="primary" size="small" @click="() => handleCopy(eduData.fkInstitutionIdName)">{{ t('stuManagement.copy') }}</el-button>
          </div>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.institution')"
            :prop="'eduBackgrounds.' + index + '.fkInstitutionId'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.fkInstitutionId"
              allow-create
              :options="institutionOption[eduData.uniqueKey]"
              :option-config="{ label: 'nameChn', value: 'id' }"
              needOption
              @getOption="(option) => getInstitutionInfo(option, eduData)"
              :isMobileCanSearch="isMobileCanSearch"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.courseCountry')"
            :prop="'eduBackgrounds.' + index + '.fkStudentAppCountryId'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.fkStudentAppCountryId"
              @change="handleSelectCountry(eduData, index)"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="eduData.fkStudentAppCountryId === 228">
          <el-form-item
            :label="t('stuManagement.fkAreaRegion')"
            :prop="'eduBackgrounds.' + index + '.fkAreaRegionId'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.fkAreaRegionId"
              :options="areaOption[eduData.uniqueKey]"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :prop="'eduBackgrounds.' + index + '.fkAreaStateId'"
            :rules="rules.needFiledRule"
          >
            <div slot="label" class="custom-label">
              <div class="label">{{t('stuManagement.state')}}<span v-show="!isDisabled(eduData.uniqueKey)" class="tip">&nbsp;({{t('common.noSelectTips')}})</span></div>
            </div>
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.fkAreaStateId"
              @change="handleSelectState(eduData)"
              :options="stateOptions[eduData.uniqueKey]"
              :option-config="{ label: 'fullName', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :prop="'eduBackgrounds.' + index + '.fkAreaCityId'"
            :rules="[
              rules.needFiledRule,
              {
                validator: checkValueNotNum(cityOptions[eduData.uniqueKey]),
                trigger: 'blur',
              }
            ]  
            "
          >
            <div slot="label" class="custom-label">
              <div class="label">{{t('stuManagement.city')}}<span v-show="!isDisabled(eduData.uniqueKey)" class="tip">&nbsp;({{t('common.noSelectTips')}})</span></div>
            </div>
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.fkAreaCityId"
              :options="cityOptions[eduData.uniqueKey]"
              :option-config="{ label: 'fullName', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.institutionAddress')"
            :prop="'eduBackgrounds.' + index + '.institutionAddress'"
            :rules="rules.noChinese"
          >
            <owl-input
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.institutionAddress"
              :placeholder="t('stuManagement.institutionAddress')"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.qualification')"
            :prop="'eduBackgrounds.' + index + '.fkEducationLevelType'"
            :rules="rules.numberSelectRule"
          >
            <owl-select
              filterable
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.fkEducationLevelType"
              :options="edulevelListByGrade[eduData.uniqueKey]"
              :option-config="{ label: 'fullName', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.gradeCategory')"
            :prop="'eduBackgrounds.' + index + '.scoreType'"
            :rules="eduData.educationType === 2 ? rules.numberSelectRule : undefined"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              filterable
              v-model="eduData.scoreType"
              @change="handleSelectScoreType(eduData)"
              :options="scoreTypeEumByType[eduData.uniqueKey]"
              :option-config="{ label: 'value', value: 'key' }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :prop="'eduBackgrounds.' + index + '.scoreValue'"
            :rules="eduData.educationType === 2 ? rules.numberSelectRule : undefined"
          >
            <div slot="label" class="custom-label">
              <div class="label">{{t('stuManagement.scoreValue')}}
                <span v-if="eduData.isEducationSubject" class="tip-dse" @click="handleHKDSEDialog(eduData)">
                  {{ isDisabled(eduData.uniqueKey) ? (eduData.studentEducationSubjectScoreVos && eduData.studentEducationSubjectScoreVos.length ? t('stuManagement.checkSubjectScores') : '') : t('stuManagement.inputSubjectScores')}}
                </span>
              </div>
            </div>
            <owl-select
              filterable
              v-if="[3, 11, 19, 126, 130].includes(eduData.scoreType)"
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.scoreValue"
              @change="handleSelectScoreValue(eduData, index)"
              :options="scoreValueOption[eduData.uniqueKey]"
              :option-config="{ label: 'scoreType', value: 'scoreType' }"
            />
            <owl-input
              v-else
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.scoreValue"
              :placeholder="t('stuManagement.twoDecimals')"
            ></owl-input>
          </el-form-item>
        </el-col>
        <template v-if="eduData.educationType === 2">
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('stuManagement.major')"
              :prop="'eduBackgrounds.' + index + '.major'"
              :rules="rules.needFiledRule"
            >
              <owl-input
                :disabled="isDisabled(eduData.uniqueKey)"
                v-model="eduData.major"
                clearable
                :placeholder="t('stuManagement.major')"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.instructedInEng')"
            :prop="'eduBackgrounds.' + index + '.studyInEnglish'"
            :rules="rules.numberSelectRule"
          >
            <owl-select
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.studyInEnglish"
              :options="studyInEnglishOption"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.inschoolTime') + t('common.startDate')"
            :prop="'eduBackgrounds.' + index + '.studyStartDate'"
            :rules="{
              required: true,
              message: t('validate.requiredField'),
              trigger: 'change',
            }"
          >
            <owl-input
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.studyStartDate"
              type="date"
              @change="handleDate(index, 1)"
              :placeholder="t('common.startDate')"
            >
            </owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.inschoolTime') + t('common.endDate')"
            :prop="'eduBackgrounds.' + index + '.studyEndDate'"
            :rules="{
              required: true,
              message: t('validate.requiredField'),
              trigger: 'change',
            }"
            class="date-end"
          >
            <owl-input
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.studyEndDate"
              type="date"
              @change="handleDate(index, 2)"
              :placeholder="t('common.endDate')"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.isComplated')"
            :prop="'eduBackgrounds.' + index + '.isCompleted'"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.isCompleted"
              @change="isCompleteChange(eduData.isCompleted, index)"
              :options="[
                { label: t('common.yes'), value: 1 },
                { label: t('common.no'), value: 0 },
              ]"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-radio>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24" v-if="eduData.isCompleted === 0">
          <el-form-item
            :label="t('stuManagement.isWillComplated')"
            :prop="'eduBackgrounds.' + index + '.isShouldCompleted'"
            :rules="rules.needFieldRadio"
          >
            <owl-radio
              :disabled="isDisabled(eduData.uniqueKey)"
              v-model="eduData.isShouldCompleted"
              :options="[
                { label: t('common.yes'), value: 1 },
                { label: t('common.no'), value: 0 },
              ]"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <owl-btn-save
        class="btn-setting"
        :loading="loading"
        v-show="isEditing(eduData.uniqueKey)"
        @save="handleSave(eduData.uniqueKey, index)"
        show-delete
        @delete="cancelEduBackground(eduData, index)"
      />
      <div :class="['more-expand', showFile.indexOf(eduData.id) <0 && 'active']" v-if="eduData.id">
        <div class="edu-upload-wrap">
          <div class="sub-title">
            {{ t("stuManagement.eduBackgroundFile")}}
          </div>
          <table class="edu-upload-table">
            <tr class="header">
              <th style="width: 288px">{{t('common.filetype')}}</th>
              <th>{{t('common.filename')}}</th>
              <th style="width: 180px">{{t('common.opera')}}</th>
            </tr>
            <tr>
              <td>TRANSCRIPT</td>
              <td :style="{cursor:'pointer', 'text-decoration': eduData.TRANSCRIPT? 'underline' : ''}" @click="getIssueFilePreview(eduData.TRANSCRIPT)">{{eduData.TRANSCRIPT ? eduData.TRANSCRIPT.fileNameOrc: t('common.noUpload')}}</td>
              <td class="operate" v-if="!hideSubmitBtn()||issueInnerAccount|| isNewAppStatus()">
                <template v-if="eduData.TRANSCRIPT">
                  <span class="btn normal" @click="updateFile(index, 'TRANSCRIPT')">{{t('stuManagement.clickUploadAgain')}}</span>
                  <span class="sperate"></span>
                  <span class="btn del" @click="delFile(index, 'TRANSCRIPT')">{{t('common.del')}}</span>
                </template>
                <span v-else class="btn" @click="uploadFile(index, 'TRANSCRIPT')">{{t('common.fileUpload')}}</span>
              </td>
            </tr>
            <tr>
              <td>GRADUATION CERTIFICATE</td>
              <td :style="{cursor:'pointer', 'text-decoration': eduData.GRADUATION_CERTIFICATE? 'underline' : ''}" @click="getIssueFilePreview(eduData.GRADUATION_CERTIFICATE)">{{eduData.GRADUATION_CERTIFICATE ? eduData.GRADUATION_CERTIFICATE.fileNameOrc: t('common.noUpload')}}</td>
              <td class="operate" v-if="!hideSubmitBtn()||issueInnerAccount|| isNewAppStatus()">
                <template v-if="eduData.GRADUATION_CERTIFICATE">
                  <span class="btn normal" @click="updateFile(index, 'GRADUATION_CERTIFICATE')">{{t('stuManagement.clickUploadAgain')}}</span>
                  <span class="sperate"></span>
                  <span class="btn del" @click="delFile(index, 'GRADUATION_CERTIFICATE')">{{t('common.del')}}</span>
                </template>
                <span v-else class="btn" @click="uploadFile(index, 'GRADUATION_CERTIFICATE')">{{t('common.fileUpload')}}</span>
              </td>
            </tr>
            <tr>
              <td>BACHELOR'S DEGREE</td>
              <td :style="{cursor:'pointer', 'text-decoration': eduData.BACHELOR_DEGREE? 'underline' : ''}" @click="getIssueFilePreview(eduData.BACHELOR_DEGREE)">{{eduData.BACHELOR_DEGREE ? eduData.BACHELOR_DEGREE.fileNameOrc: t('common.noUpload')}}</td>
              <td class="operate" v-if="!hideSubmitBtn()||issueInnerAccount || isNewAppStatus()">
                <template v-if="eduData.BACHELOR_DEGREE">
                  <span class="btn normal" @click="updateFile(index, 'BACHELOR_DEGREE')">{{t('stuManagement.clickUploadAgain')}}</span>
                  <span class="sperate"></span>
                  <span class="btn del" @click="delFile(index, 'BACHELOR_DEGREE')">{{t('common.del')}}</span>
                </template>
                <span v-else class="btn" @click="uploadFile(index, 'BACHELOR_DEGREE')">{{t('common.fileUpload')}}</span>
              </td>
            </tr>
            <tr>
              <td>STUDY CERTIFICATE</td>
              <td :style="{cursor:'pointer', 'text-decoration': eduData.STUDY_CERTIFICATE? 'underline' : ''}" @click="getIssueFilePreview(eduData.STUDY_CERTIFICATE)">{{eduData.STUDY_CERTIFICATE ? eduData.STUDY_CERTIFICATE.fileNameOrc: t('common.noUpload')}}</td>
              <td class="operate" v-if="!hideSubmitBtn()||issueInnerAccount|| isNewAppStatus()">
                <template v-if="eduData.STUDY_CERTIFICATE">
                  <span class="btn normal" @click="updateFile(index, 'STUDY_CERTIFICATE')">{{t('stuManagement.clickUploadAgain')}}</span>
                  <span class="sperate"></span>
                  <span class="btn del" @click="delFile(index, 'STUDY_CERTIFICATE')">{{t('common.del')}}</span>
                </template>
                <span v-else class="btn" @click="uploadFile(index, 'STUDY_CERTIFICATE')">{{t('common.fileUpload')}}</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="switch-btn" @click="switchFileTable(eduData.id)"><span class="switch-btn-text">教育背景资料上传</span><i class="el-icon-d-arrow-left arrow"></i></div>
      </div>
    </el-form>
    <input type="file" class="edu-bgm-file" ref="file" @change="onFileChange" />
    <div class="form-no-data-tip" v-if="!stuEduBackgroundFormData.eduBackgrounds.length">
      {{t('validate.noFormDataTip')}}
    </div>
    <el-dialog :visible.sync="showHKDSEDialog" custom-class="hkdse-dialog">
      <div slot="title" style="text-align: left;">
        <el-upload
          v-if="[2, 3, 4, 5, 6].includes(companyId()) && !hideSubmitBtn() && isBmsLogin() && currentEduData.scoreType === 2"
          :disabled="uploadLoading"
          action=""
          :show-file-list="false"
          :before-upload="beforeUpload"
          :http-request="handleUploadFile"
          >
          <el-button slot="trigger" :disabled="isDisabled(currentEduData.uniqueKey)" type="primary" :loading="uploadLoading" class="submit-form">
            <span class="file-identify">{{ t("stuManagement.fileIdentificationForHKDSE") }}</span>
          </el-button>
        </el-upload>
      </div>
      <el-form size="small" class="subject-branch-form" :model="subjectBranch" ref="subjectBranch">
        <el-row :gutter="20" v-for="(item, index) in subjectBranch.studentEducationSubjectScoreVos" :key="item.key">
          <el-col :span="11">
            <el-form-item :prop="'studentEducationSubjectScoreVos.' + index + '.subjectType'" :rules="rules.needFiledRule">
              <owl-select
                :disabled="isDisabled(currentEduData.uniqueKey)"
                style="width: 100%"
                :placeholder="t('common.branchSubjectType')"
                v-model="item.subjectType"
                :options="scoreSubjectTypeList[currentEduData.uniqueKey]"
                :option-config="{ label: 'subjectTypeName', value: 'subjectType' }"
                needOption
                @getOption="(option) => getSubjectScoreType(option, item)"
              ></owl-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item :prop="'studentEducationSubjectScoreVos.' + index + '.value'" :rules="rules.needFiledRule">
              <owl-select
                :disabled="isDisabled(currentEduData.uniqueKey)"
                style="width: 100%"
                :placeholder="t('common.branchSubjectValue')"
                v-model="item.value"
                allow-create
                :options="conditionTypeList[currentEduData.uniqueKey]"
                :option-config="{ label: 'scoreType', value: 'score' }"
                needOption
                @getOption="(option) => getSubjectScoreText(option, item)"
              ></owl-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item v-if="index !== 0 && !isDisabled(currentEduData.uniqueKey)">
              <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="deleteSubjectBranch(index)"></el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align:left;" v-if="!isDisabled(currentEduData.uniqueKey)">
        <el-button type="text" @click="addSubjectBranch">+增加科目</el-button>
      </div>
      <div slot="footer" style="text-align:center;" v-if="!isDisabled(currentEduData.uniqueKey)">
        <el-button size="small" type="primary" @click="saveHKDSE">提交保存</el-button>
        <el-button size="small" @click="closeHKDSEDialog">取消关闭</el-button>
      </div>
    </el-dialog>
    <IntelligentIdentificationDialog v-model="identificationDialog" @get-result-Identified="getResultIdentified"></IntelligentIdentificationDialog>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import { copyToClipboard } from '@/utils/tool';
import {
  getStateOptions,
  getCityOptions,
  getScoreTypeEumByTypeApi,
  getEdulevelListByGradeApi,
  saveStuEduSchoolData,
  deleteStudentEducation,
  getStuEduBackgroundDataApi,
  getScoreTypeApi,
  getAreaRegionByCountryId,
  uploadIssueStudentEduApi,
  updateStudentFileApi,
  getDeleteStudentEduFile,
  uploadFileApi,
  enCvParseApi,
  newGetInstitutionDataToIssue,
  getIssueFilePreviewApi,
  getIsEducationSubjectApi,
  selectByConditionTypeApi,
  getScoreSubjectTypeListApi,
  getInstitutionDataToIssueEduApi,
  getEducationSubjectInfoApi,
} from "@/api/common.js";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlRadio from "/packages/components/OwlRadio.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import IntelligentIdentificationDialog from '../../components/IntelligentIdentification/IntelligentIdentificationInfoDialog.vue';

export default {
  name: "StudentEducationBackground",
  mixins: [mixin, i18n],
  inject: ["stuid", "ctryOptions", "hideSubmitBtn", 'companyId', 'isBmsLogin',"platformType", "platformLimitList", "issueInnerAccount", "isNewAppStatus"],
  components: { OwlInput, OwlSelect, OwlRadio, OwlBtnSave, OwlBtnSet, IntelligentIdentificationDialog },
  props: [ "required", "showMainTitle" ],
  data() {
    return {
      currentEduData: {},
      fkPlatformType:this.platformType(),
      uploadLoading: false,
      identificationDialog: false,
      showHKDSEDialog: false,
      loading: false,
      showDetailData: false, // 是否显示详情
      viewOrderOption: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
      ],
      conditionTypeList: {},
      scoreSubjectTypeList: {},
      scoreSubjectTypeHKDSE: [],
      // 教育层次
      levelOption: [],
      // 新学历
      edulevelListByGrade: {},
      // 成绩类别
      scoreTypeEumByType: {},
      studyInEnglishOption: [],
      isCompletedOption: [],
      // 平均分
      scoreValueOption: {},
      subjectBranch: {
        studentEducationSubjectScoreVos:[
          {
            scoreText: '',
            scoreValue: '',
            scoreType: "",
            subjectType: "",
            value:'',
            key: 1,
          },
        ]
      },
      stuEduBackgroundFormData: {
        eduBackgrounds: [
          // {
          //   TRANSCRIPT:null, GRADUATION_CERTIFICATE:null, BACHELOR_DEGREE:null, STUDY_CERTIFICATE:null,
          //   educationType: "", // 教育背景类型 1.中学课程（必选）2.大学以上课程（选填）
          //   fkStudentAppCountryId: "", // 教育背景国家
          //   fkAreaStateId: "", // 教育背景省份
          //   fkAreaCityId: "", // 教育背景城市
          //   fkAreaRegionId: "", // 教育背景区域（英国）
          //   fkInstitutionId: "", // 教育背景学校名称
          //   institutionAddress: "", // 学校详细地址
          //   fkStudentId: "", // 学生id
          //   fkEducationLevelType: "", // 学历等级Id
          //   major: "", // 主修
          //   scoreType: 0, // 学术成绩类型
          //   scoreValue: 0, // 学术成绩数值
          //   studyInEnglish: "", // 0以英语为教学和考试语言，1以英语为教学语言但不包括考试，2否
          //   studyStartDate: "", // 在校开始时间
          //   studyEndDate: "", // 在校结束时间
          //   isCompleted: 0, // 是否完成：0否/1是
          //   isShouldCompleted: "", // 是否将会完成：0否/1是
          //   viewOrder: "", // 排序（倒序），数字由大到小排列
          //   studentEducationSubjectScoreVos: [],
          //   isEducationSubject: false, // 判断是否有分科成绩
          // },
        ],
      },
      eduBgRules: {},
      stateOptions: {},
      cityOptions: {},
      areaOption: {},
      institutionOption: {},
      showFile: [],
      uploadFileData: {
        uploading: false,
        changeEvent: null,
      },
      hkdseFkGuids:{},
    };
  },
  computed: {
    countryOptions() {
      return this.ctryOptions();
    },
    isDisabled() {
      return (uniqueKey) => {
        return (this.hideSubmitBtn() && !this.issueInnerAccount() || !this.isEditing(uniqueKey))
      }
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  async mounted() {
    this.levelOption = [
      { id: 1, name: this.t("stuManagement.highSchool") },
      { id: 2, name: this.t("stuManagement.higherEducation") },
    ];
    this.studyInEnglishOption = [
      { id: 0, name: this.t("stuManagement.inEngAndExam") },
      { id: 1, name: this.t("stuManagement.inEngNotExam") },
      { id: 2, name: this.t("common.isNot") },
    ];
    this.isCompletedOption = [
      { id: 1, name: this.t("common.yes") },
      { id: 0, name: this.t("common.no") },
    ];
    this.getScoreSubjectHKDSE();
  },
  methods: {
    async handleHKDSEDialog(eduData) {
      // if (eduData.id && eduData.scoreType) {
      //   const { data } = await getEducationSubjectInfoApi(eduData.id);
      //   if (data.data && data.data.length) {
      //     data.data = data.data.map((item, index) => {
      //       item.key = Date.now() + index;
      //       item.subjectType = item.subjectType+'';
      //       return item;
      //     });
      //     this.subjectBranch.studentEducationSubjectScoreVos = data.data;
      //   }
      // }
      if (eduData.scoreType && eduData.studentEducationSubjectScoreVos && eduData.studentEducationSubjectScoreVos.length) {
        const datas = eduData.studentEducationSubjectScoreVos.map((item, index) => {
          item.key = Date.now() + index;
          item.subjectType = item.subjectType+'';
          return item;
        });
        this.subjectBranch.studentEducationSubjectScoreVos = datas;
      }
      this.currentEduData = eduData;
      this.showHKDSEDialog = true;
    },
    saveHKDSE() {
      this.$refs.subjectBranch.validate((valid) => {
        if (valid) {
          this.currentEduData.studentEducationSubjectScoreVos = this.subjectBranch.studentEducationSubjectScoreVos;
          this.showHKDSEDialog = false;
        }
      });
    },
    getSubjectScoreType(option, item) {
      if (!option) {
        return;
      }
      item.scoreType = option.scoreType;
    },
    getSubjectScoreText(option, item) {
      if (!option && item.value) {
        if (item.scoreType !==2) {
          item.scoreText = item.value;
          item.scoreValue=-1
        } else {
          const scoreValue = Number(item.value);
          switch (scoreValue) {
            case 1:
              item.scoreText = '1';
              break;
            case 2:
              item.scoreText = '2';
              break;
            case 3:
              item.scoreText = '3';
              break;
            case 4:
              item.scoreText = '4';
              break;
            case 5:
              item.scoreText = '5';
              break;
            case 6:
              item.scoreText = '5*';
              break;
            case 7:
              item.scoreText = '5**';
          }
        }
        console.log(item.scoreText);
        return;
      } else {
        item.scoreText = option.scoreType;
        item.scoreValue = option.value
      }
    },
    deleteSubjectBranch(index) {
      const subjectBranchs = this.subjectBranch.studentEducationSubjectScoreVos;
      subjectBranchs.splice(index, 1);
      this.subjectBranch.studentEducationSubjectScoreVos = subjectBranchs;
    },
    addSubjectBranch() {
      const subjectBranchs = this.subjectBranch.studentEducationSubjectScoreVos;
      subjectBranchs.push({
        scoreText: '',
        scoreValue: '',
        scoreType: '',
        subjectType: '',
        value:'',
        key: Date.now(),
      });
      this.subjectBranch.studentEducationSubjectScoreVos = subjectBranchs;
    },
    closeHKDSEDialog() {
      this.initSubjectBranch();
      this.showHKDSEDialog = false;
    },
    initSubjectBranch() {
      this.subjectBranch.studentEducationSubjectScoreVos = [
        {
          scoreText: '',
          scoreValue: '',
          scoreType: "",
          subjectType: "",
          value:'',
          key: Date.now()+'1',
        },
      ];
    },
    beforeUpload(file) {
      const fileTypes = [
        'image/jpeg', 'image/png', 'application/pdf', 'image/bmp', 'image/gif'
      ];
      const type = file.type.toLowerCase();
      if (!fileTypes.includes(type)) {
        this.$msge.error('不支持该文件的格式，请重新上传符合格式要求的文件！');
        return false;
      }
      return true;
    },
    async handleUploadFile(params) {
      this.uploadLoading = true;
      const formData =  new FormData();
      const file = params.file;
      formData.append('file', file);
      formData.append('type', 'issueFileHkDseRec');
      try {
        const { data } = await enCvParseApi(formData);
        this.uploadLoading = false;
        const resData = JSON.parse(data.data);
        if (resData.data.error_code) {
          return this.$msge.error(resData.data.error_msg);
        }
        if ((resData.result === 'success') && (Object.keys(resData.data).length) || (resData.status === 'success')) {
          const fkGuids=resData.fkGuIds;
          if(fkGuids){
            this.hkdseFkGuids={};
            this.hkdseFkGuids[fkGuids]='HKDSE';
          }
          const result = resData.data.subject;
          const subjectBranchs = []
          result.forEach((elem, index) => {
            const item = this.scoreSubjectTypeHKDSE.find((item) => {
              return elem.subjectId && Number(elem.subjectId) === Number(item.subjectType);
            });
            subjectBranchs.push({
              subjectType: elem.subjectId ? elem.subjectId + '' : '',
              scoreText: elem.subjectLevel,
              scoreType: item ? item.scoreType : '',
              scoreValue: elem.subjectLevelId ? elem.subjectLevelId : '',
              value:elem.subjectLevelId ? elem.subjectLevelId : '',
              key: Date.now() + index,
            });
          });
          this.subjectBranch.studentEducationSubjectScoreVos = subjectBranchs;
        }
      } catch (err) {
        this.uploadLoading = false;
      }
    },
    fileIdentification() {
      this.identificationDialog = true;
    },
    getResultIdentified({ workExp, eduBack }) {
      if (workExp && workExp.length) {
        this.$emit('setIdentifiedData', { workExp });
      }
      if (eduBack && eduBack.length) {
        this.$emit('setIdentifiedData', { eduBack });
      }
      if (eduBack && eduBack.length) {
        this.$emit('setActiveName', 'education');
      } else if (workExp && workExp.length) {
        this.$emit('setActiveName', 'workExperience');
      }
    },
    handleCopy(text) {
      copyToClipboard(text);
    },
    getInstitutionInfo(item, eduData) {
      const key = eduData.uniqueKey;
      if (!item) {
        return;
      }
      if (item.fkIssueCountryId) {
        eduData.fkStudentAppCountryId = item.fkIssueCountryId;
        if (item.fkIssueCountryId === 228) {
          this.getAreaData(item.fkIssueCountryId, key);
        }
      }
      if (item.fkAreaStateId) {
        if (item.fkIssueCountryId) {
          this.getStateData(item.fkIssueCountryId, key, eduData, item.fkAreaStateId);
        } else {
          eduData.fkAreaStateId = null;
          this.$set(this.stateOptions, key, []);
        }
      } else {
        if (item.fkIssueCountryId) {
          this.getStateData(item.fkIssueCountryId, key, eduData);
        } else {
          eduData.fkAreaStateId = null;
          eduData.fkAreaCityId = null;
          this.$set(this.stateOptions, key, []);
          this.$set(this.cityOptions, key, []);
        }
      }
      if (item.fkIssueCountryId && item.fkAreaStateId && item.fkAreaCityId) {
        this.getCityData(item.fkAreaStateId, key, eduData, item.fkAreaCityId);
      } else if (item.fkIssueCountryId && item.fkAreaStateId) {
        this.getCityData(item.fkAreaStateId, key, eduData);
      } else {
        eduData.fkAreaCityId = null;
        this.$set(this.cityOptions, key, []);
      }
      if (item.address) {
        eduData.institutionAddress = item.address;
      } else {
        eduData.institutionAddress = '';
      }
    },
    getKeyFile(list, type){
      if(!list || !list.length) return null;
      return list.find(item=> item.typeKey == type)
    },
    async setIdentifiedData(data) {
      const eduBackArr = [];
      let i = 0;
      const eduLen = this.stuEduBackgroundFormData.eduBackgrounds.length;
      for await (let item of data) {
        let key = Date.now();
        const viewOrder = item.viewOrder ? 1*item.viewOrder : eduLen === 0 ? ++i : '';
        let studentEducationSubjectScoreVos=[];
        if(item.studentEducationSubjectScoreVos&&item.studentEducationSubjectScoreVos.length){
          for(const vosItem of item.studentEducationSubjectScoreVos){
            if(vosItem.scoreValue!=='-1.00'){
              vosItem['value']=vosItem.scoreValue;
            }else{
              vosItem['value']=vosItem.scoreText;
            }
          }
          studentEducationSubjectScoreVos=item.studentEducationSubjectScoreVos;
        }
        const eduData = {
          id: item.id || '',
          educationType: item.educationType,
          fkStudentAppCountryId: this.getIdTypeValue(item.fkStudentAppCountryId),
          fkStudentAppCountryName: item.fkStudentAppCountryName,
          fkAreaStateId: this.getIdTypeValue(item.fkAreaStateId),
          fkAreaStateName: item.fkAreaStateName,
          fkAreaCityId: this.getIdTypeValue(item.fkAreaCityId),
          fkAreaCityName: item.fkAreaCityName,
          fkAreaRegionId: this.getIdTypeValue(item.fkAreaRegionId),
          fkInstitutionId: this.getIdTypeValue(item.fkInstitutionId),
          fkInstitutionIdName: item.fkInstitutionIdName ? item.fkInstitutionIdName : '',
          institutionAddress: item.institutionAddress,
          fkStudentId: this.stuid(),
          fkEducationLevelType: item.fkEducationLevelType ? item.fkEducationLevelType * 1 : '',
          major: item.major,
          scoreType: item.scoreType ? item.scoreType * 1:'',
          scoreValue: this.getIdTypeValue(item.scoreValue),
          studyInEnglish: item.studyInEnglish,
          studyStartDate: item.studyStartDate && item.studyStartDate.split(" ")[0],
          studyEndDate: item.studyEndDate && item.studyEndDate.split(" ")[0],
          isCompleted: (item.isCompleted === '') ? 1 : item.isCompleted * 1,
          isShouldCompleted: (item.isShouldCompleted === '') ? item.isShouldCompleted : item.isShouldCompleted * 1,
          viewOrder,
          uniqueKey: key,
          TRANSCRIPT: this.getKeyFile(item.mediaAndAttachedDtos, 'TRANSCRIPT'),
          GRADUATION_CERTIFICATE: this.getKeyFile(item.mediaAndAttachedDtos, 'GRADUATION_CERTIFICATE'),
          BACHELOR_DEGREE: this.getKeyFile(item.mediaAndAttachedDtos, 'BACHELOR_DEGREE'),
          STUDY_CERTIFICATE: this.getKeyFile(item.mediaAndAttachedDtos, 'STUDY_CERTIFICATE'),
          studentEducationSubjectScoreVos,
          isEducationSubject: false, // 判断是否有分科成绩
          fkGuidAndTypeKey:item.fkGuidAndTypeKey?item.fkGuidAndTypeKey:{}
        };
        eduBackArr.push(eduData);
        this.handleSelectScoreType(eduData, 'init');
        if (item.fkStudentAppCountryId) {
          await this.getStateData(eduData.fkStudentAppCountryId, key, item);
          await this.getAreaData(eduData.fkStudentAppCountryId, key);
        }
        if (eduData.fkAreaStateId && !isNaN(Number(eduData.fkAreaStateId))) {
          await this.getCityData(eduData.fkAreaStateId, key, item);
        }
        if (item.educationType) {
          await this.getEdulevelListByGrade(item.educationType, key);
          await this.getScoreTypeEumByType(item.educationType, key);
        }
        await this.getInstitutionData(key);
        this.switchEditStatus(key);
        await this.waitMoment(1);
      }
      this.stuEduBackgroundFormData.eduBackgrounds.unshift(...eduBackArr);
    },
    // type 1初始更新，2提交更新
    async setInitData(data, type) {
      if(!data || !data.length) return;
      if (type === 2) {
        const len = this.stuEduBackgroundFormData.eduBackgrounds.length;
        this.stuEduBackgroundFormData.eduBackgrounds.splice(0, len);
      }
      this.loading = true;
      const eduDatas = [];
      for await (let item of data) {
        let key = item.uniqueKey ? item.uniqueKey : Date.now();
        let studentEducationSubjectScoreVos=[];
        if(item.studentEducationSubjectScoreVos&&item.studentEducationSubjectScoreVos.length){
          for(const vosItem of item.studentEducationSubjectScoreVos){
            if(vosItem.scoreValue!=='-1.00'){
              vosItem['value']=vosItem.scoreValue;
            }else{
              vosItem['value']=vosItem.scoreText;
            }
          }
          studentEducationSubjectScoreVos=item.studentEducationSubjectScoreVos;
        }
        const eduData = {
          id: item.id || '',
          educationType: item.educationType,
          fkStudentAppCountryId: this.getIdTypeValue(item.fkStudentAppCountryId),
          fkStudentAppCountryName: item.fkStudentAppCountryName,
          fkAreaStateId: this.getIdTypeValue(item.fkAreaStateId),
          fkAreaStateName: item.fkAreaStateName,
          fkAreaCityId: this.getIdTypeValue(item.fkAreaCityId),
          fkAreaCityName: item.fkAreaCityName,
          fkAreaRegionId: this.getIdTypeValue(item.fkAreaRegionId),
          fkInstitutionId: this.getIdTypeValue(item.fkInstitutionId),
          fkInstitutionIdName: item.fkInstitutionIdName ? item.fkInstitutionIdName : '',
          institutionAddress: item.institutionAddress,
          fkStudentId: this.stuid(),
          fkEducationLevelType: item.fkEducationLevelType ? item.fkEducationLevelType * 1 : '',
          major: item.major,
          scoreType: item.scoreType ? item.scoreType * 1:'',
          scoreValue: this.getIdTypeValue(item.scoreValue),
          studyInEnglish: item.studyInEnglish,
          studyStartDate:
            item.studyStartDate && item.studyStartDate.split(" ")[0],
          studyEndDate: item.studyEndDate && item.studyEndDate.split(" ")[0],
          isCompleted: (item.isCompleted === '') ? 1 : item.isCompleted * 1,
          isShouldCompleted: (item.isShouldCompleted === '') ? item.isShouldCompleted : item.isShouldCompleted * 1,
          viewOrder: item.viewOrder ? 1 * item.viewOrder : '',
          uniqueKey: key,
          TRANSCRIPT: this.getKeyFile(item.mediaAndAttachedDtos, 'TRANSCRIPT'),
          GRADUATION_CERTIFICATE: this.getKeyFile(item.mediaAndAttachedDtos, 'GRADUATION_CERTIFICATE'),
          BACHELOR_DEGREE: this.getKeyFile(item.mediaAndAttachedDtos, 'BACHELOR_DEGREE'),
          STUDY_CERTIFICATE: this.getKeyFile(item.mediaAndAttachedDtos, 'STUDY_CERTIFICATE'),
          studentEducationSubjectScoreVos,
          isEducationSubject: false, // 判断是否有分科成绩
          fkGuidAndTypeKey:item.fkGuidAndTypeKey?item.fkGuidAndTypeKey:{}
        };
        eduDatas.push(eduData);
        this.handleSelectScoreType(eduData, 'init');
        await this.waitMoment(1);
        if (item.fkStudentAppCountryId) {
          await this.getStateData(item.fkStudentAppCountryId, key, item,item.fkAreaStateId);
          await this.getAreaData(item.fkStudentAppCountryId, key);
        }
        if (item.fkAreaStateId && !isNaN(Number(item.fkAreaStateId))) {
          await this.getCityData(item.fkAreaStateId, key, item);
        }
        await this.getInstitutionData(key);
        await this.getEdulevelListByGrade(item.educationType, key);
        await this.getScoreTypeEumByType(item.educationType, key);
        if (!item.id) {
          this.switchEditStatus(key);
        }
      }
      this.stuEduBackgroundFormData.eduBackgrounds = eduDatas;
      this.loading = false;
    },
    // 分数下拉
    async selectByConditionType(scoreType, key) {
      const res = await selectByConditionTypeApi(scoreType);
      if ([3, 11, 19, 126, 130].includes(scoreType)) {
        this.$set(this.scoreValueOption, key, res.data.datas || []);
      } else {
        this.$set(this.conditionTypeList, key, res.data.datas || []);
      }
    },
    // 获取HKDSE科目下拉
    async getScoreSubjectHKDSE() {
      const res = await getScoreSubjectTypeListApi(2);
      this.scoreSubjectTypeHKDSE = res.data.datas || [];
    },
    // 科目下拉
    async getScoreSubjectTypeList(scoreType, key) {
      let datas;
      if (scoreType !== 2) {
        const res = await getScoreSubjectTypeListApi(scoreType);
        datas = res.data.datas;
      } else {
        if(!this.scoreSubjectTypeHKDSE.length){
          await this.getScoreSubjectHKDSE();
        }
        datas = this.scoreSubjectTypeHKDSE;
      }
      this.$set(this.scoreSubjectTypeList, key, datas || []);
    },
    // 判断是否有分科成绩数据
    async getIsEducationSubject(scoreType, eduData) {
      const res = await getIsEducationSubjectApi(scoreType);
      eduData.isEducationSubject = res.data.data;
    },
    // 获取成绩类别
    async getScoreTypeEumByType(grade, key) {
      const res = await getScoreTypeEumByTypeApi(grade);
      this.$set(this.scoreTypeEumByType, key, res.data.datas || []);
    },
    // 获取学历信息
    async getEdulevelListByGrade(grade, key) {
      const res = await getEdulevelListByGradeApi(grade);
      this.$set(this.edulevelListByGrade, key, res.data.datas || []);
    },
    // 获取区域
    async getAreaData(countryId, key){
      if(countryId != 228)return;
      const area = this.countryOptions.find(item=>item.id == countryId)
      if(!area || !area.fkAreaCountryId){
        return;
      }
      const res = await getAreaRegionByCountryId(area.fkAreaCountryId)
      this.$set(this.areaOption, key, res.data.datas || []);
    },
    // 获取省
    async getStateData(countryId, key, eduData, id) {
      const res = await getStateOptions(countryId);
      this.$set(this.stateOptions, key, res.data.datas || []);
      if (!res.data.datas || !res.data.datas.length) {
        eduData.fkAreaCityId = null;
      } else {
        if (id) {
          eduData.fkAreaStateId = id;
        } else {
          eduData.fkAreaStateId = null;
        }
      }
    },
    getIssueFilePreview(fileData) {
      const fileKey = fileData.fileKey;
      getIssueFilePreviewApi(fileKey).then(res => {
        const url = res.data.data;
        if (url) {
          window.open(url);
        }
      })
    },
    // 获取城市
    async getCityData(stateId, key, eduData, id) {
      if (!stateId || isNaN(Number(stateId))) return;
      const res = await getCityOptions(stateId);
      this.$set(this.cityOptions, key, res.data.datas || []);
      if (!res.data.datas || !res.data.datas.length) {
        eduData.fkAreaCityId = null;
      } else {
        if (id) {
          eduData.fkAreaCityId = id;
        } else {
          eduData.fkAreaCityId = null;
        }
      }
    },
    // 获取学校 selectStatus 获取id(0城市 1省份 2国家)
    async getInstitutionData(key) {
      const { data } = await getInstitutionDataToIssueEduApi();
      this.$set(this.institutionOption, key, data.datas || []);
    },
    handleChangeViewOrder(eduData) {
      const viewOrder = eduData.viewOrder;
      const eduBackgrounds = this.stuEduBackgroundFormData.eduBackgrounds;
      let num = 0;
      eduBackgrounds.map((eduBackground) => {
        if(viewOrder.toString() === eduBackground.viewOrder.toString()){
          num++;
        }
      })
      if (num > 1) {
        this.$alrt(
          this.t("stuManagement.eduBackgroundNumMsg", {
            num: viewOrder,
          }),
          this.t('common.tip'),
          {
            confirmButtonText: this.t("common.confirm"),
          }
        );
        eduData.viewOrder = null;
      }
    },
    // 增加教育背景
    handleAdd() {
      if(this.hasEdit()) return;
      if(this.stuEduBackgroundFormData.eduBackgrounds.length >= 3){
        this.$alrt(this.t("stuManagement.edubgmNumCount"), this.t('common.tip'),{
          confirmButtonText: this.t("common.confirm"),
        })
        return;
      }
      const uniqueKey = Date.now();
      this.stuEduBackgroundFormData.eduBackgrounds.unshift({
        educationType: "",
        fkStudentAppCountryId: "",
        fkStudentAppCountryName: "",
        fkAreaStateId: null,
        fkAreaStateName: "",
        fkAreaCityId: null,
        fkAreaCityName: "",
        fkAreaRegionId: "",
        fkInstitutionId: null,
        fkInstitutionIdName: "",
        institutionAddress: "",
        fkStudentId: this.stuid(),
        fkEducationLevelType: "",
        major: "",
        scoreType: "",
        scoreValue: "",
        scoreValueName: "",
        studyInEnglish: "",
        studyStartDate: "",
        studyEndDate: "",
        isCompleted: 1,
        isShouldCompleted: "",
        viewOrder: "",
        uniqueKey: uniqueKey,
        isShowInput: false, // 是否展示输入框
        TRANSCRIPT: null,
        GRADUATION_CERTIFICATE: null,
        BACHELOR_DEGREE: null,
        STUDY_CERTIFICATE: null,
        studentEducationSubjectScoreVos: [],
        isEducationSubject: false, // 判断是否有分科成绩
        fkGuidAndTypeKey:{},// 存放guid，传给后端，后端自动把扫描文件上传到附件，格式{"GUID":"CV","GUID":"HKDSE"}
      });
      this.getInstitutionData(uniqueKey);
      this.switchEditStatus(uniqueKey);
      this.onFormStatusChanged();
    },
    // 学校类型
    educationTypeChange(eduData) {
      const key = eduData.uniqueKey;
      const educationType = eduData.educationType;
      eduData.fkEducationLevelType = '';
      eduData.scoreType = '';
      eduData.scoreValue = '';
      eduData.scoreValueName = '';
      eduData.studentEducationSubjectScoreVos = [];
      this.initSubjectBranch();
      this.getEdulevelListByGrade(educationType, key);
      this.getScoreTypeEumByType(educationType, key);
      // eduData.fkInstitutionId = null;
      // if (eduData.educationType && eduData.fkStudentAppCountryId) {
        //   const key = eduData.uniqueKey;
        //   this.$delete(this.institutionOption, key);
        //   this.getInstitutionData(key, eduData.fkStudentAppCountryId, 2, eduData.educationType);
        // }
    },
    // 获取国家/省州/城市名字
    getAreaName(arr, value) {
      let name = "";
      if (arr.length > 0) {
        for (const key of arr) {
          if (key.id === value) {
            name = key.name;
            break;
          }
        }
      }
      return name;
    },
    // 国家
    handleSelectCountry(stuEduData) {
      const countryId = stuEduData.fkStudentAppCountryId;
      const key = stuEduData.uniqueKey;
      stuEduData.fkAreaStateId = '';
      stuEduData.fkAreaCityId = '';
      stuEduData.fkAreaRegionId = '';
      this.$delete(this.stateOptions, key);
      this.$delete(this.cityOptions, key);
      stuEduData.fkStudentAppCountryName = this.getAreaName(
        this.countryOptions,
        countryId
      );
      
      if (countryId && !isNaN(Number(countryId))) {
        this.getStateData(countryId, key, stuEduData);
        this.getAreaData(countryId, key);
      }
    },
    // 省
    handleSelectState(stuEduData) {
      stuEduData.fkAreaCityId = '';
      const key = stuEduData.uniqueKey;
      const countryId = stuEduData.fkStudentAppCountryId;
      const stateId = stuEduData.fkAreaStateId;
      if (countryId === 92) {
        return;
      }
      this.$delete(this.cityOptions, key);
      // 根据省份id获取学校，状态为1
      if (stateId && !isNaN(Number(stateId))) {
        this.getCityData(stateId, key, stuEduData);
      }
    },
    
    // 成绩类别change
    handleSelectScoreType(eduBack, type) {
      const val = eduBack.scoreType;
      const key = eduBack.uniqueKey;
      if (!type) {
        eduBack.scoreValue = "";
        eduBack.studentEducationSubjectScoreVos = [];
        this.initSubjectBranch();
      }
      if (!val) {return;}
      this.getScoreSubjectTypeList(val, key);
      this.selectByConditionType(val, key);
      this.getIsEducationSubject(val, eduBack);
    },
    // 平均分change - 下拉选项
    handleSelectScoreValue(eduBack, index) {
      const id = eduBack.scoreValue;
      const key = eduBack.uniqueKey;
      const option = this.scoreValueOption[key];
      this.stuEduBackgroundFormData.eduBackgrounds[index].scoreValueName =
        this.getAreaName(option, id);
    },
    // 检查平均分输入框是否符合规则
    checkInputScoreValue(scoreVal, typeId, index) {
      let valid = true;
      switch(typeId) {
        case 12:
          valid = this.checkScoreValue('validate.gradeCategoryGpa4', scoreVal, 4);
          break;
        case 13:
          valid = this.checkScoreValue('validate.gradeCategoryGpa4d2', scoreVal, 4.2);
          break;
        case 23:
          valid = this.checkScoreValue('validate.gradeCategoryGpa4d3', scoreVal, 4.3);
          break;
        case 14:
          valid = this.checkScoreValue('validate.gradeCategoryGpa4d5', scoreVal, 4.5);
          break;
        case 15:
          valid = this.checkScoreValue('validate.gradeCategoryGpa5', scoreVal, 5);
          break;
        case 16:
          valid = this.checkScoreValue('validate.gradeCategoryGpa7', scoreVal, 7);
          break;
        case 17:
          valid = this.checkScoreValue('validate.gradeCategoryGpa9', scoreVal, 9);
          break;
        case 18:
          valid = this.checkScoreValue('validate.gradeCategoryPercentage', scoreVal, 100, 40, 2);
          break;
        case 22:
          valid = this.checkScoreValue('validate.gradeCategoryGpa10', scoreVal, 10);
          break;
        case 26:
          valid = this.checkScoreValue('validate.gradeCategoryGpa12', scoreVal, 12);
          break;
        case 21:
          valid = this.checkScoreValue('validate.gradeCategoryGpa', scoreVal, 10000);
          break;
        case 24:
          valid = this.checkScoreValue('validate.gradeCategoryGre', scoreVal, 10000, 0, 1);
          break;
        case 25:
          valid = this.checkScoreValue('validate.gradeCategoryGmat', scoreVal, 10000, 0, 1);
          break;
        case 20:
          valid = this.checkScoreValue('validate.otherRequirements', scoreVal, 10000, 0);
          break;
        case 4:
          valid = this.checkScoreValue('validate.iBDiploma', scoreVal, 45, 10);
          break;
        case 132:
          valid = this.checkScoreValue('validate.advancedPlacement', scoreVal, 5, 1, 0);
          break;
        case 105:
          valid = this.checkScoreValue('validate.gaoKao', scoreVal, 100, 0, 0);
          break;
        case 2:
          valid = this.checkScoreValue('validate.hKDSEHongKong', scoreVal, 99, 10, 0);
          break;
        case 102:
          valid = this.checkScoreValue('validate.ontarioSSD', scoreVal, 100, 0, 0);
          break;
        case 106:
          valid = this.checkScoreValue('validate.sat1', scoreVal, 1600, 400, 0);
          break;
        case 118:
          valid = this.checkScoreValue('validate.sat2', scoreVal, 1600, 400, 0);
          break;
        case 119:
          valid = this.checkScoreValue('validate.act', scoreVal, 99, 10, 0);
          break;
      }
      if (!valid) {
        this.stuEduBackgroundFormData.eduBackgrounds[index].scoreValue = '';
        return false;
      }
      return true;
    },
    // decimalsLimit: 限制小数个数
    checkScoreValue(str, scoreVal, max, min = 1, decimalsLimit = 2) {
      if (String(scoreVal).indexOf('%') > -1) {
        scoreVal = scoreVal.replace('%', '');
      }
      let decimalsNum = String(scoreVal).indexOf(".") == -1 ? 0 : String(scoreVal).split('.')[1].split('').length;
      if (decimalsNum > decimalsLimit || (min > scoreVal || max < scoreVal)) {
        this.$alrt(this.t(str), this.t("common.tip"),
          {
            confirmButtonText: this.t("common.confirm"),
          }
        );
        return false;
      }
      return true;
    },
    // checkScoreValueInteger(str, scoreVal, max, min = 1) {
    //   let decimalsNum = String(scoreVal).indexOf(".") == -1 ? 0 : String(scoreVal).split('.')[1].split('').length;
    //   if (decimalsNum !== 0 || (min > scoreVal || max < scoreVal)) {
    //     this.$alrt(this.t(str), this.t("common.tip"),
    //       {
    //         confirmButtonText: this.t("common.confirm"),
    //       }
    //     );
    //     return false;
    //   }
    //   return true;
    // },
    // 是否完成Change
    isCompleteChange(val, index) {
      if (val === 1) {
        this.stuEduBackgroundFormData.eduBackgrounds[index].isShouldCompleted = "";
      }
    },
    // 删除教育背景
    delEduBackground(data, index, uniqueKey) {
      if(this.loading) return;
        const vueComponent = this.$refs['eduBgForm'+uniqueKey][0];
      if (vueComponent) {
        vueComponent.clearValidate();
      }
      this.$confIssue(this.t("validate.delMsgEduInfo"), "", {
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
        type: "warning",
      }).then(async () => {
        // const index = this.stuEduBackgroundFormData.eduBackgrounds.indexOf(data);
        if (data.id) {
          this.loading = true;
          try {
            await deleteStudentEducation(data.id);
          } catch {
            this.$msge({
              type: "success",
              message: this.t("common.deleteFail"),
            });
            return;
          } finally {
            this.loading = false;
          }
        }
        this.isEditing(data.uniqueKey) && this.switchEditStatus(data.uniqueKey)
        this.$delete(this.stateOptions, data.uniqueKey);
        this.$delete(this.cityOptions, data.uniqueKey);
        this.$delete(this.institutionOption, data.uniqueKey);
        this.$delete(this.scoreValueOption, data.uniqueKey);
        this.$delete(this.edulevelListByGrade, data.uniqueKey);
        this.$delete(this.scoreTypeEumByType, data.uniqueKey);
        // if (index !== -1) {
        this.stuEduBackgroundFormData.eduBackgrounds.splice(index, 1);
        // }
        if (!this.stuEduBackgroundFormData.eduBackgrounds.length) {
          this.addUncompletedStatus()
        }
        this.onFormStatusChanged()
        this.$msge.success(this.t("common.deleteSucc"));
      });
    },
    // 取消教育背景
    cancelEduBackground(data, index){
      if(this.loading) return;
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        const backup = this.getEditBackupByKey(data.uniqueKey)
        this.switchEditStatus(data.uniqueKey)
        this.onFormStatusChanged()
        if(backup) {
          this.$set(this.stuEduBackgroundFormData.eduBackgrounds, index, backup)
          this.loading = true;

          await this.getStateData(backup.fkStudentAppCountryId, data.uniqueKey, data)
          await this.getCityData(backup.fkAreaStateId, data.uniqueKey, data)
          await this.getInstitutionData(data.uniqueKey)
          this.loading = false;
        } else {
          this.stuEduBackgroundFormData.eduBackgrounds.splice(index, 1)
        }
      })
    },
    // 在校时间的结束时间
    handleDate(index, num) {
      const data = this.stuEduBackgroundFormData.eduBackgrounds[index];
      if (data.studyEndDate) {
        const nowTime = (new Date()).getTime();
        const endTime = (new Date(data.studyEndDate)).getTime();
        if (endTime <= nowTime) {
          data.isCompleted = 1;
        } else {
          data.isCompleted = 0;
          data.isShouldCompleted = 1;
        }
      }
      if (data.studyStartDate && data.studyEndDate) {
        if (data.studyStartDate > data.studyEndDate) {
          this.$alrt(
            this.t("validate.checkDate", {
              name: this.t("stuManagement.durationSch"),
            }),
            this.t("common.tip"),
            {
              confirmButtonText: this.t("common.confirm"),
              callback: () => {
                if (num === 1) {
                  data.studyStartDate = "";
                } else {
                  data.studyEndDate = "";
                }
              },
            }
          );
        }
      }
    },
    checkValueNotNum(option){
      const validRegstFun = (rule, value, callback) => {
        if (value&&!/\D/.test(value)&&(!option||!option.length)) {
          callback(new Error('不能纯数字'));
        } else {
          callback()
        }
      };
      return validRegstFun;
    },
    async checkFormStatus() {
      if(!this.required) return {
        isEdit: this.editList.length == 0, 
        validate: true,  
        error: this.t('validate.education')
      };
      let validate = true;
      try {
        const temp = Object.keys(this.$refs);
        if(this.$refs && temp && temp.length > 1) {
          for await(let key of temp) {
            if (key !== 'file' && key !== 'subjectBranch') {
              if (this.$refs[key] && !this.$refs[key].length) {
                this.$refs[key] = null;
                continue;
              }
              if(this.$refs[key] && this.$refs[key].length){
                await this.$refs[key][0].validate();
              }
            }
          }
        }
      } catch {
        validate = false;
      }
      return {
        isEdit: this.editList.length == 0 && this.stuEduBackgroundFormData.eduBackgrounds.length > 0,
        validate,
        error: this.t('validate.education')
      }
    },

    async handleSave(uniqueKey, index) {
      const item = this.stuEduBackgroundFormData.eduBackgrounds[index];
      let error = false;
      this.$refs['eduBgForm'+uniqueKey][0].validate((valid) => {
        if(!valid) error = true;
      })
      if(error) return;
      if(!this.checkInputScoreValue(item.scoreValue, item.scoreType, index)) return;
      // 如果有hkdse的guid，那么就覆盖原来的hkdse的guid
      // fkGuidAndTypeKey的构成：guid为属性（会随机变化），CV/HKDSE等等为值（写死）
      if(this.hkdseFkGuids&&Object.keys(this.hkdseFkGuids).length&&item.fkGuidAndTypeKey){
        let keys={};
        if(Object.keys(item.fkGuidAndTypeKey).length){
          Object.keys(item.fkGuidAndTypeKey).forEach((filterItem)=>{
            if(item.fkGuidAndTypeKey[filterItem]!=='HKDSE'){
              const typesKey={};
              typesKey[filterItem]=item.fkGuidAndTypeKey[filterItem];
              keys=Object.assign(keys,typesKey)
            }
            item.fkGuidAndTypeKey=Object.assign(keys,this.hkdseFkGuids)
          })
        }else{
          item.fkGuidAndTypeKey=Object.assign(keys,this.hkdseFkGuids)
        }
      }
      this.loading = true;
      try {
        const res = await saveStuEduSchoolData(item);
        if (res.data.code === "1003") {
          if (this.stuEduBackgroundFormData.eduBackgrounds.length === 1) {
            this.addCompletedStatus()
          }
          this.switchEditStatus(uniqueKey);
          this.onFormStatusChanged()
          this.$msge({
            message: this.t("common.saveSucc"),
            type: "success",
          });
          if (!item.id) item.id = res.data.data;
        } else {
          this.$msge({
            message: this.t("common.saveFail"),
            type: "error",
          });
        }
      } finally {
        this.loading = false;
      }
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      const index = this.stuEduBackgroundFormData.eduBackgrounds.findIndex(item=>item.uniqueKey == key)
      if(index >= 0) await this.handleSave(key, index)
    },
    // 编辑
    updateEdu(data) {
      // if(this.hasEdit()) return; // 可多条编辑
      this.switchEditStatus(data.uniqueKey, data);
      this.onFormStatusChanged()
    },
    onFormStatusChanged(){
      let lng = this.stuEduBackgroundFormData.eduBackgrounds.length
      let editing = this.editList.length;
      let proccess = 0;
      if(lng){
        proccess = Math.floor((lng - editing) / lng * 100);
      }
      this.$emit('on-porccess-changed', proccess)
    },
    switchFileTable(id){
      const index = this.showFile.indexOf(id);
      index === -1 ? this.showFile.push(id) : this.showFile.splice(index, 1);
    },
    // type: 'TRANSCRIPT' | 'GRADUATION_CERTIFICATE' | 'BACHELOR_DEGREE' | 'STUDY_CERTIFICATE'
    uploadFile(index, typeKey){
      const item = this.stuEduBackgroundFormData.eduBackgrounds[index]
      this.$refs['file'].click()
      this.uploadFileData = {
        changeEvent: async (files) => {
          const loading = this.$msge.loading(this.t('common.fileUpload')+'');
          try {
            const ret = await uploadIssueStudentEduApi({
              files,
              stuId: this.stuid(),
              fkStudentEduId: item.id,
              typeKey,
            })
            if(ret.data.code == 1003){
              const data = ret.data.datas[0];
              const nameIndex = data.fileNameOrc.lastIndexOf('.')
              const fileNameOrc = data.fileNameOrc.substring(0, nameIndex)
              item[typeKey] = {
                id: data.fkMediaAndAttachedId,
                fileNameOrc: fileNameOrc,
                fkFileGuid: data.fileGuid,
                fileKey: data.fileKey,
              }
              this.$msge.success(this.t('common.saveSucc') + '')
            } else {
              this.$msge.error(this.t('common.saveFail') + '')
            }
            loading.close()
            this.$refs['file'].value = ''; // 清空input的value值
            this.uploadFileData.changeEvent = null;
          } catch(e) {
            loading.close()
          }
        }
      }
    },
    onFileChange(e){
      if(e.target.files.length && e.target.files[0]){
        const {name, size} = e.target.files[0]
        const nameIndex = name.lastIndexOf('.')
        const subfix = name.substring(nameIndex+1);
        if(subfix.toLowerCase() != 'pdf'){
          this.$alrt(this.t(`stuManagement.uploadLimit`), "", {
            confirmButtonText: this.t("common.confirm"),
          })
          e.target.value = '';
          return;
        }
        // 限制2M
        const sizeLimit = 2;
        if ((size / 1024 / 1024) > sizeLimit) {
          this.$msge.error(this.t("stuManagement.uploadSize",{limit: sizeLimit}));
          return false;
        }
      }
      this.uploadFileData.changeEvent && this.uploadFileData.changeEvent(e.target.files)
    },
    delFile(index, type){
      const edubackground = this.stuEduBackgroundFormData.eduBackgrounds[index];
      this.$confIssue(this.t("validate.delMsgFile"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        await getDeleteStudentEduFile(edubackground[type].id)
        this.$msge({
          type: "success",
          message: this.t("common.deleteSucc"),
        });
        edubackground[type] = null;
      })
      
    },
    // 更新
    updateFile(index, type){
      const itemType = this.stuEduBackgroundFormData.eduBackgrounds[index][type];
      const item = this.stuEduBackgroundFormData.eduBackgrounds[index];
      this.$refs['file'].click()
      this.uploadFileData = {
        changeEvent: async (files) => {
          const res = await uploadIssueStudentEduApi({
            files,
            stuId: this.stuid(),
            fkStudentEduId: item.id,
            typeKey: type,
            oldFkGuId: itemType.fkFileGuid || ''
          })
          
          if (!res.data || res.data.code != '1003') {
            const message = res.data.message || this.t("common.uploadFail");
            this.$msge({
              message,
              type: "error",
            });
            return;
          }
          const fileKey = res.data.datas[0].fileKey;
          const newFkGuId = res.data.datas[0].fileGuid;
          const id = res.data.datas[0].fkMediaAndAttachedId;
          const nameIndex = res.data.datas[0].fileNameOrc.lastIndexOf('.')
          const fileNameOrc = res.data.datas[0].fileNameOrc.substring(0, nameIndex)
          // await updateStudentFileApi(this.stuid(), type, newFkGuId, itemType.fkFileGuid)
          this.$msge({
            message: this.t("common.updateSucc"),
            type: "success",
          });
          itemType.fkFileGuid = newFkGuId
          itemType.fileNameOrc = fileNameOrc
          itemType.id = id;
          itemType.fileKey = fileKey;
          this.uploadFileData.changeEvent = null;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.more-expand{
  position: relative;
  width: 100%;
  border-bottom: 1px solid #DCDFE6;
  margin-bottom:15px;
  .switch-btn{
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    width: 186px;
    height: 26px;
    background: #DCDFE6;
    border: 1px solid #DCDFE6;
    border-radius: 0px 0px 8px 8px;
    cursor: pointer;
    transition: opacity .25s;
    text-align: center;
    .switch-btn-text {
      margin-right: 10px;
    }
    .arrow{
      font-size: 16px;
      display: inline-block;
      transform: rotate(-90deg);
      line-height: 24px;
      transition: transform .2s;
    }
    &:hover{
      opacity: .8;
    }
  }
  &.active{
    .switch-btn{
      .arrow{
        transform: rotate(90deg);
      }
    }
    .edu-upload-wrap{
      display: block;
    }
  }
}
.edu-upload-wrap{
  width: 100%;
  overflow: auto;
  display: none;
}
.btn-setting{
  padding-bottom: 24px;
}
.edu-bgm-file{
  width: 0;
  height: 0;
  opacity: 0;
}
.edu-upload-table{
  border-spacing: 0;
  border-radius: 4px;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  min-width: 750px;
  margin-bottom: 44px;
  td,th{
    vertical-align: middle;
    text-align: center;
    border: 1px solid #dcdfe6;
    padding: 10px;
    transition: background-color .2s;
  }
  
  td{
    font-size: 14px;
    color: #333;
    &.operate{
      white-space: nowrap;
      span{
        display: inline-block;
        vertical-align: middle;
      }
      .btn{
        cursor: pointer;
        transition: opacity .25s;
        color: #FF9C3B;
        &:hover{
          opacity: .8;
        }
        &.del{
          opacity: 1;
          color:#AEAEAE;
          &:hover{
            color: #ec808d;
          }
        }
        &.normal{
          color: #333;
        }
      }
      .sperate{
        margin: 0 20px;
        height: 12px;
        border-left: 1px solid #333;
        
      }
    }
  }
  tr:nth-child(2n+1){
    td{
      background-color: #FFF9F3;
    }
  }
 
  .header{
    th{
      font-weight: bold;
      font-size: 14px;
      color: #fff;
      background: #FF9C3B;
      border-color:#FF9C3B;
      border-right-color: #dcdfe6;
      &:last-child{
        border-right-color: #FF9C3B;
      }
    }
  }
}
.cus-label {
  display: flex;
  align-items: center;
  text-align: left;
  color: #ff9700;
  .text {
    color: #303333;
  }
}
.custom-label{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  .label{
    font-size: 12px;
  }
  .tip{
    color: red;
  }
  .tip-dse {
    display: inline-block;
    margin-left: 2em;
    color: blue;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.stu-education-bg {
  overflow: hidden;
  .subject-branch-form.el-form {
    min-height: 300px;
    height: 30vh;
    overflow-y: auto;
    padding-top: 20px;
  }
  .el-dialog__body {
    padding: 20px;
  }
}
@media screen  and (max-width: 768px) {
  .hkdse-dialog {
    width: 95% !important;
  }
}
</style>